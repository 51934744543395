import React from "react";
import ObservableSelect from "../../ObjectEditor/ObservableSelect/ObservableSelect";
import FilterManager from "../../../managers/FilterManager";
import ObjectData from "../../../models/ObjectData";
import ServerManager from "../../../managers/ServerManager";
import InfoManager from "../../../managers/InfoManager";
import {Rights} from "../../../models/TaggableData";
import PopupInputDialog from "../../PopupInputDialog/PopupInputDialog";


export default class FilterBar extends React.Component {

    onChange = (name, value = null) => FilterManager.set(name, value);

    lineRef = React.createRef();

    onCleanFilter() {
        FilterManager.clean();
        try {
            this.lineRef.current.value = '';
        }
        catch (e) {}

    }

    render() {
        const {projects, filterMap, objectCount, me} = this.props;
        const {filterObjectClass, filterObjectGroup, line} = filterMap;

        const objectClassOptions = ObjectData.classes.map(item => {
            if (item[1] === 'null') return {id: '', name: 'All classes'};
            return {id: item[1], name: item[0]}
        });

        const projectList = Object.values(projects).map(it => ({id: it.projectId || 'unassigned', name: it.description}) );
        const projectListOptions = [{id: 'all', name: 'All projects'}, ...projectList];

        return (
            <div className="FBContainer">

                <ObservableSelect name='filterObjectClass'
                                  value={filterObjectClass}
                                  optionList={objectClassOptions}
                                  onChange={this.onChange}
                                  keyPrefix='FBClass'
                                  className='OBSelect SBInput' />

                <ObservableSelect name='filterObjectGroup'
                                  value={filterObjectGroup || 'unassigned'}
                                  optionList={projectListOptions}
                                  onChange={this.onChange}
                                  keyPrefix='FBProject'
                                  className='OBSelect SBInput' />

                <i className="fas fa-star likeBtn FBIconButton"
                   title='Show favourite objects'
                   onClick={() => this.onChange('filterFavourites', !filterMap.filterFavourites)} />

                <i className="fas fa-eye-slash likeBtn FBIconButton"
                   title='Show disabled objects'
                   style={{color: 'var(--danger)', verticalAlign: 'text-bottom'}}
                   onClick={() => this.onChange('filterDisabled', !filterMap.filterDisabled)} />

                <span className={'likeBtn FBCleanAll'}
                      title='Clean filter'
                      onClick={ e => this.onCleanFilter()}>

                    <i className='fas fa-filter' />
                    <sup>
                        <i className='fas fa-times' />
                    </sup>
                </span>

            </div>
        )
    }
}
