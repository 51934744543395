import React from 'react';
import OptionsBar from "../components/OptionsBar";
import {connect} from "react-redux";
import ObjectList from "../components/ObjectList";
import ObjectEditor from "../components/ObjectEditor/ObjectEditor";
import storeDispatcher from "../storeDispatcher";
import SightViewer from "../components/ObjectEditor/Activator/SightViewer";
import FilterBar from "../components/ObjectList/standard-mode/FilterBar";
import Map from "../components/ObjectLocationMap";
import ErrorBoundary from "../components/ErrorBoundary";
import MediaEditor from "../components/ObjectEditor/MediaEditor";
import SearchBar from "../components/ObjectList/standard-mode/SearchBar";
import Utils from "../managers/Utils";


class ObjectListPage extends React.Component {

    constructor(props) {
        super(props);
        if (props.history) Utils.routerHistory = props.history
        if (props.match.params.objectId) storeDispatcher.objectEdit(props.match.params.objectId);
    }

    historyPush = (path) => {
        this.props.history.push(path);
    };

    render() {
        return (
            <div style={{width: "100%"}}>
                <OptionsBar>
                    <ErrorBoundary>
                        <SearchBar />
                        <FilterBar projects={this.props.projects}
                                   me={this.props.me}
                                   objectCount={this.props.objectCount}
                                   filterMap={this.props.filterMap} />
                    </ErrorBoundary>
                </OptionsBar>
                <div className='MainContainer'>
                    <ObjectList historyPush={this.historyPush} />
                    {/*<ObjectEditor historyPush={this.historyPush} />
                    <SightViewer />

                    <MediaEditor />

                    <Map />*/}
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    me: state.me,
    objects: state.objects,
    projects: state.projects,
    objectCount: state.objectCount,
    filterMap: state.filterMap
});

export default connect(mapStateToProps)(ObjectListPage);
