import actions from './actions'
import {store} from "../store"


export default Object.keys(actions).reduce( (acc: Record<string, any>, key: string) => {

    return {
        ...acc,
        // @ts-ignore
        [key]: (value: any) => store.dispatch( actions[key](value) )
    }
}, {});


// export default bindActionCreators(actions, store.dispatch);
