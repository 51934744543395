import ServerManager from "./ServerManager";
import storeDispatcher from "../storeDispatcher";
import {store} from "../store";
import ObjectData from "../models/ObjectData";
import UserData from "../models/UserData";
import ProjectData from "../models/ProjectData";
import FilterManager from "./FilterManager";
import {produce} from "immer";
import CreatorData from "../models/CreatorData";


export default class StoreManager {

    static _requestedData = { // for prevention of requests duplication
        creators: {},
        objects: {},
        objectSelectorList: {}
    };

    static orderingList = [];

    static updateProjectList() {
        ServerManager.instance.getProjectList().then(
            (groups) => {
                if (!groups) return;
                storeDispatcher.projectsUpdate(groups.reduce(
                    (acc, cur) => {
                        acc[cur.groupId || cur.projectId] = new ProjectData(cur);
                        return acc;
                    }, {}
                ));
            }
        ).catch(
            (e) => console.log('Unable to update project list', e)
        );
    }

    static updateProject(projectId) {
        ServerManager.instance.getProjectData(projectId)
            .then(
                answer => {
                    const project = new ProjectData(answer);
                    if (!project.projectId) return;

                    storeDispatcher.projectsUpdate({...store.getState().projects, [project.projectId]: project});
                })
            .catch((e) => console.log('Unable to update user list', e))

    }

    static removeProject(projectId) {
        const projects = {...store.getState().projects};
        delete projects[projectId];

        storeDispatcher.projectsUpdate(projects);
    }

    static removeUser(userId) {
        const users = {...store.getState().users};
        delete users[userId];

        storeDispatcher.userListUpdate(users);
    }

    static updateObjectList(skip = 0, filterMap = FilterManager.filterMap) {
        if (skip === 0) {
            // storeDispatcher.objectsUpdate({});
            StoreManager.orderingList = [];
            // this._requestedData.objects = {};
        }

        ServerManager.instance.getObjectList({skip, ...filterMap})
            .then(
                 (list) => {
                    if (!list) return;
                    const downloadList = list.objectList || [];

                    // Save ordering of objects
                    const order = downloadList.map(item => item.objectId);
                     StoreManager.orderingList = [...StoreManager.orderingList, ...order];

                     // Display temporary templates

                    const objectTemplateMap = downloadList.reduce((acc, cur) => {
                        return {...acc, [cur.objectId]: new ObjectData(cur)}
                    }, {});
                    storeDispatcher.objectsUpdate({ ...objectTemplateMap, ...store.getState().objects});
                    storeDispatcher.objectCounter(parseInt(list.count) || 0);

                    // Download content
                    downloadList.forEach(async item => {
                        // TODO: Check revision of stored version of object. If it is old - request fresh one
                        StoreManager.addObject(item.objectId).then();
                    });
                })
            .catch(
                (e) => console.log('Unable to update object list', e)
            );
    }

    static updateUserList(searchLine = null) {
        ServerManager.instance.getUserList(searchLine)
            .then(
                (userList = []) => {

                    const users = userList.reduce((acc, cur) => {
                        const user = new UserData(cur);
                        return {
                            ...acc,
                            [user.userId]: user
                        }
                    }, {});

                    storeDispatcher.userListUpdate(users);
                })
            .catch((e) => console.log('Unable to update user list', e))

    }

    static updateUser(userId) {
        ServerManager.instance.getUserInfo(userId)
            .then(
                answer => {
                    console.log(answer);
                    if (!answer.status) return;

                    const user = new UserData(answer.user);
                    storeDispatcher.userUpdate(user);
                })
            .catch((e) => console.log('Unable to update user list', e))

    }

    static async addObject(objectId, addToOrder = true) {
        if (this._requestedData.objects[objectId]) return
        this._requestedData.objects[objectId] = true

        const object = await ServerManager.instance.getLightObject(objectId)
        if (!object) {
            return
        }

        if (FilterManager.isNotEmpty && !FilterManager.checkObjectConformity(object)) {
            console.log("Object not conforms to filter!")
            return
        }

        if (addToOrder && !StoreManager.orderingList.includes(objectId)) {
            StoreManager.orderingList = [objectId, ...StoreManager.orderingList]
        }

        storeDispatcher.objectsUpdate({
            ...store.getState().objects,
            [objectId]: object
        })

    }

    static async updateObject(objectId) {
        const object = await ServerManager.instance.getLightObject(objectId)
        if (!object) {
            return
        }

        if (FilterManager.isNotEmpty && !FilterManager.checkObjectConformity(object)) {
            console.log("Object not conforms to filter!")
            return
        }

        const heldObject = store.getState().objects[objectId]
        if (heldObject) {
            // Merge old and new data
            for (let media of object.mediaEntries) {
                const heldSpeek = heldObject.getSpeek(media.mediaId)
                if (heldSpeek) {
                    // merging of media data
                    if (heldSpeek.textData && heldSpeek.textFileId === media.textFileId) {
                        media.textData = heldSpeek.textData
                    }
                    if (heldSpeek.fileData && heldSpeek.fileId === media.fileId) {
                        media.fileData = heldSpeek.fileData
                    }
                }
            }

            for (let activator of object.activators) {
                const heldActivator = heldObject.getActivator(activator.activatorId)
                if (heldActivator && heldActivator.recogImage) {
                    // merging of activator data
                    activator.recogImage = heldActivator.recogImage
                }
            }
        }

        storeDispatcher.objectsUpdate({
            ...store.getState().objects,
            [objectId]: object
        })

    }

    static async updateObjectSpeekData(objectId, mediaId) {
        const object = store.getState().objects[objectId];
        if (!object) return false;

        const speekIndex = object.mediaEntries.findIndex(it => it.mediaId === mediaId);
        if (speekIndex < 0) return false;

        let {fileId, fileData, textFileId, textData} = object.mediaEntries[speekIndex];

        if (fileId && !fileData) {
            const data = await ServerManager.instance.getSoundtrackData(fileId);
            if (data) {
                fileData = data;
            } else console.log('Damaged media fileData!', {mediaId, fileId});
        }

        if (textFileId && !textData) {
            const data = await ServerManager.instance.getTextData(textFileId);
            if (data) {
                textData = data;
            } else console.log('Damaged media textData!', {mediaId, textFileId});
        }

        const updatedObject = produce(object, obj => {
            obj.mediaEntries[speekIndex].textData = textData;
            obj.mediaEntries[speekIndex].fileData = fileData;
        });

        storeDispatcher.objectsUpdate({
            ...store.getState().objects,
            [objectId]: updatedObject
        });

        return updatedObject.mediaEntries[speekIndex];
    }

    static async setMediaFileData(objectId, mediaId, fileData) {
        const object = store.getState().objects[objectId];
        if (!object) return false;

        const speekIndex = object.mediaEntries.findIndex(it => it.mediaId === mediaId);
        if (speekIndex < 0) return false;

        const updatedObject = produce(object, obj => {
            obj.mediaEntries[speekIndex].fileData = fileData;
        });

        storeDispatcher.objectsUpdate({
            ...store.getState().objects,
            [objectId]: updatedObject
        });

        return true;
    }

    static async updateActivatorsData(objectId) {
        const object = produce(store.getState().objects[objectId], () => {});
        if (!object) return false;

        let hasChanges = false;

        for (let activator of object.activators) {
            if (activator.isSight && !activator.recogImage) {
                hasChanges = true;
                activator.recogImage = await ServerManager.instance.getSightImage(activator.activatorId);
            }
        }

        if (hasChanges) storeDispatcher.objectsUpdate({
            ...store.getState().objects,
            [objectId]: object
        });

        return object;
    }

    static removeObject(objectId) {
        const objects = store.getState().objects;
        if (objects[objectId]) {
            // objects[objectId] = new ObjectData({objectId, tags: {}});
            delete objects[objectId];
            storeDispatcher.objectsUpdate(objects);
            FilterManager.downloadedObjectCount--;
        }
    }

    static updateObjectSelectorMap(objectClass) {
        if (this._requestedData.objectSelectorList[objectClass]) {
            return
        }
        // activate blocking
        this._requestedData.objectSelectorList[objectClass] = true

        ServerManager.instance.getObjectSelectorMap(objectClass).then(result => {
            if (!result.status) {
                console.log((result.msg || 'Server error'));
                return;
            }

            const map = result.data.reduce((acc, it) => {
                acc[it.objectId] = new ObjectData(it);
                return acc;
            }, {});

            const objectSelectorMap = {
                ...store.getState().objectSelectorMap,
                [objectClass]: map
            };

            // console.log(objectSelectorMap);
            storeDispatcher.setObjectSelectorMap(objectSelectorMap);

            // disable blocking for downloading
            this._requestedData.objectSelectorList[objectClass] = false;
        });
    }

    static updateExtendableObjects() {
        if (this._requestedData.extendableObjects) {
            return
        }
        // activate blocking
        this._requestedData.extendableObjects = true

        ServerManager.instance.getExtendableObjects().then(result => {
            if (!result.status) {
                console.log((result.msg || 'Server error'));
                return;
            }

            const extendableObjects = result.data
                .reverse()
                .reduce((acc, it) => {
                    /**
                     *  it consists from objectId and tags{}
                     */
                    acc[it.objectId] = new ObjectData(it);
                    return acc;
                }, {});

            storeDispatcher.updateExtendableObjects(extendableObjects)

            // disable blocking for downloading
            this._requestedData.extendableObjects = false
        });
    }

    static updateMe(userId) {
        ServerManager.instance.getUserInfo(userId).then(result => {
            if (result.status) {
                storeDispatcher.updateMe(new UserData(result.user));
            }
        });
    }

    static updateAuthor(userId) {
        ServerManager.instance.getAuthorInfo(userId).then(result => {
            if (result.status) {
                const user = {...result.user, userId, objectCount: result.objectCount, _id: null};
                delete user._id;
                storeDispatcher.updateAuthor(user);
            }
        });
    }

    static updateCreator(userId) {
        if (this._requestedData.creators[userId]) return;
        this._requestedData.creators[userId] = true;

        ServerManager.instance.getCreatorsInfo(userId).then(result => {
            if (result.status) {
                const creators = store.getState().creators;


                if (!result.data) {
                    console.error(`Can't take info about creator ${userId}`);
                    return;
                }

                const creator = new CreatorData(result.data);
                storeDispatcher.updateCreators({...creators, [userId]: creator});
            }
        });
    }

    static updatePendingObjectList(withActionList = true) {
        ServerManager.instance.getPendingObjectList().then(
            ObjectList => {
                if (Object.keys(ObjectList) < 1 ) {
                    storeDispatcher.updatePendingData({
                        list: [],
                        objectId: null
                    });
                    return;
                }
                const objectId = Object.keys(ObjectList)[0];
                if (withActionList) {
                    StoreManager.updatePendingData(objectId)
                } else {
                    storeDispatcher.updatePendingData({
                        list: [],
                        objectId
                    });
                }
            }
        );
    }

    static updatePendingData(objectId) {
        ServerManager.instance.getPendingActions(objectId).then(result => {
            if (result.status) {
                const {list} = result;

                if (!list || list.length < 1) {
                    this.updatePendingObjectList();
                    return;
                }

                storeDispatcher.updatePendingData({
                    list,
                    objectId
                });
                console.log({objectId, list});
                StoreManager.addObject(objectId, false).then();
            }
        });
    }

    static updateGroupInvitations() {
        ServerManager.instance.getGroupInvitations()
            .then((answer) => {
                storeDispatcher.updateGroupInvitations({
                    list: answer.invitations,
                    hasInvitation: Object.keys(answer.invitations || []).length
                })
            })

    }

    static updateGroupInvitationsExisting(value) {
        const groupInvitations = store.getState().groupInvitations
        storeDispatcher.updateGroupInvitations({
            ...groupInvitations,
            hasInvitation: value
        })
    }

    static changeHomePath(route = {id: "root", title: ""}) {
        if (Array.isArray(route)) {
            return storeDispatcher.updateHomePath(route)
        }

        if (route.id === "root") {
            return storeDispatcher.updateHomePath([])
        }

        const currentPath = [...store.getState().homePagePath]
        const routeIndex = currentPath.findIndex(it => it.id === route.id)

        let newPath = []
        if (routeIndex > -1) { // navigation to existing part of path
            newPath = currentPath.slice(0, routeIndex + 1)
        }
        else { // navigation to new route
            newPath = [...currentPath, route]
        }

        if (newPath.length > 0) {
            FilterManager.clean(false)
            FilterManager.set("filterObjectGroup", newPath[0].id, false)
            if (newPath.length > 1) {
                FilterManager.set("filterParentId", newPath[newPath.length - 1].id, false)
            }
            else {
                FilterManager.set("filterParentId", false, false)
            }
        }
        else {
            FilterManager.clean(false)
        }

        console.log({newPath, filter:FilterManager.filterMap})
        FilterManager.applyChanges()
        storeDispatcher.updateHomePath(newPath)

    }
}

