import React from 'react'
import {connect} from "react-redux"
import './ProjectList.css'
import ProjectListItem from "./ProjectListItem"
import StoreManager from "../../managers/StoreManager"


class ProjectList extends React.Component {
    componentDidMount() {
        StoreManager.updateProjectList()
    }

    render() {
        const {projects, history, hierarchicalBehaviour = false} = this.props;

        console.log(this.props);

        return (
            <div className="ObjectListBlock">
                {
                    (Object.values(projects)).map((item) => {
                        return (
                            <ProjectListItem history={history}
                                             project={item}
                                             hierarchicalBehaviour={hierarchicalBehaviour}
                                             key={'projectItem_' + item.projectId} />
                        )
                    })
                }
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    const {projects} = state;

    return {
        projects
    };
};

export default connect(mapStateToProps)(ProjectList);
