import React from 'react';
import './UserList.css';

export default class UserListItem extends React.Component {

    openProfile = () => {
        console.log(this.props.user);
        this.props.history.push(`/profile/${this.props.user.userId}`);
    };

    onlineIcon () {
        const {user} = this.props;

        return (
            <div className="UserItemOnline">
                <i className={`fas fa-circle ${ + user.isOnlineWeb ? '' : 'hided'}`} title="Online from website" />

                <i className={`fas fa-mobile-alt ${ + user.isOnlineApp ? '' : 'hided'}`} title="Online from mobile application" />
            </div>
        )
    }

    render() {
        const {user} = this.props;

        return (
            <div className="likeBtn ULItem" onClick={this.openProfile}>
                <img className="ULIAvatar"
                     src={user.avatarData}
                     alt="" />

                <div className="ULIDescription">
                    {user.name}
                </div>
            </div>
        )

       /* return (
            <div className="UserListItem" onClick={this.openProfile}>

                <div className="UserItemLeft">
                    <div className="UserIconBlock">
                        <img src={user.avatarData} alt='' />
                    </div>

                    {this.onlineIcon()}
                </div>

                <div className="UserItemRight">
                    <div className="UserItemName">
                        {user.name} <small>( {user.email} )</small>
                    </div>

                    <div className="UserItemLastVisit">
                        Last visit: {user.lastVisitDate}
                    </div>

                    <div className="UserItemRegistered">
                        Registered: {user.registrationDate}
                    </div>
                </div>

            </div>
        )*/

    }
}
