import React from 'react';
import './Header.css';
import mainLogo from '../../resources/mainLogo2.svg'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Utils from "../../managers/Utils";
import {Rights} from "../../models/TaggableData";

class Header extends React.Component {

    render() {
        const {isLoggedIn, me, pendingData, groupInvitations: {hasInvitation}} = this.props;
        const isAdmin = me && me._data && me.hasRight(Rights.ADMIN);
        const isDeveloper = me && me._data && me.hasRight(Rights.DEVELOPER);
        const isSuper = me && me._data && me.isSuperAdmin;
        const hasPendingObject = !!pendingData.objectId;

        return (
            <div id='Header'>
                <div id='MainLogo'>
                    <img src={mainLogo} alt='' />
                    <i id="MainMenuHamburger" className="far fa-bars" />
                </div>
                <div id='MainMenu' className={isLoggedIn ? '' : 'hided'}>
                    <Link to='/home' className='likeBtn MainMenuItem'>
                        Home
                    </Link>

                    <Link to='/objects' className='likeBtn MainMenuItem'>
                        All objects
                    </Link>

                    <Link to='/projects' className='likeBtn MainMenuItem'>
                        Groups
                        {
                            hasInvitation ?
                                <div className='MMNotificationLabel'>
                                    {
                                        hasInvitation
                                    }
                                </div>
                                : null
                        }
                    </Link>

                    <Link to='/users' className={'likeBtn MainMenuItem' + (isAdmin?'':' hided')}>
                        Users
                    </Link>

                    <Link to='/profile' className='likeBtn MainMenuItem'>
                        Profile
                    </Link>

                    <Link to='/pending' className={'likeBtn MainMenuItem' + (isAdmin?'':' hided')}>
                        Pending
                        {
                            hasPendingObject ? <i className='fas fa-bell MMPendingAlert'/> : null
                        }
                    </Link>

                    <Link to='/admin' className={'likeBtn MainMenuItem' + (isSuper?'':' hided')}>
                        SuperAdmin
                    </Link>

                    <Link to='/repository' className={'likeBtn MainMenuItem' + (isDeveloper?'':' hided')}>
                        Repository
                    </Link>

                    <div className='likeBtn MainMenuItem' onClick={Utils.signOut}>
                        Sign out
                    </div>
                </div>
            </div>
        )
    }

}



const mapStateToProps = ({isLoggedIn, me, pendingData, groupInvitations}) => ({
    isLoggedIn,
    me,
    pendingData,
    groupInvitations
});
export default connect(mapStateToProps)(Header);
