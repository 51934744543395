import {initialState} from "./initialState";
// import {act} from "react-dom/test-utils";


export const reducer = (state = initialState, action) => {

    switch(action.type) {

        case 'OBJECTS_UPDATE': {
            // console.log('OBJECTS_UPDATE', state.objects, action.value);
            return {
                ...state,
                objects: action.value
            };
        }

        case 'OBJECT_EDIT': {
            return {
                ...state,
                editingObjectId: action.value
            };
        }

        case 'SPEEK_EDIT': {
            const {mediaId, parentId, mediaType = 'speak'} = action.value;

            return {
                ...state,
                editingMediaId: mediaId,
                newMediaType: mediaType,
                editingSpeekParentId: parentId
            };
        }

        case 'SIGHT_VIEWER': {
            return {
                ...state,
                viewerActivatorId: action.value
            };
        }

        case 'SPEEK_PLAY': {
            return {
                ...state,
                playingSpeekId: action.value
            };
        }

        case 'PROJECTS_UPDATE': {
            return {
                ...state,
                projects: action.value
            };
        }

        case 'USERS_UPDATE': {
            return {
                ...state,
                users: action.value
            };
        }

        case 'USER_UPDATE': {
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.value.userId]: action.value
                }
            };
        }

        case 'LOG_IN': {
            return {
                ...state,
                isLoggedIn: action.value === undefined ? undefined : !!action.value,
                me: action.value ? action.value : initialState.me
            };
        }

        case 'SET_FILTER': {
            const {name, value} = action.value;
            return {
                ...state,
                filterMap: {
                    ...state.filterMap,
                    [name]: value
                }
            };
        }

        case 'CLEAN_FILTER': {
            return {
                ...state,
                filterMap: {...action.value}
            };
        }

        case 'OBJECT_COUNT': {
            return {
                ...state,
                objectCount: action.value
            };
        }

        case 'SELECT_OBJECT': {
            const objectId = action.value;
            const selected = [...state.selectedObjectList];

            if (selected.includes(objectId)) {
                selected.splice(selected.indexOf(objectId));
            } else {
                selected.push(objectId);
            }

            return {
                ...state,
                selectedObjectList: selected
            };
        }

        case 'SET_OBJECT_SELECTION': {
            return {
                ...state,
                selectedObjectList: action.value
            };
        }

        case 'SET_OBJECT_SELECTOR_MAP': {
            return {
                ...state,
                objectSelectorMap: action.value
            };
        }

        case 'UPDATE_ME': {
            return {
                ...state,
                me: action.value
            };
        }

        case 'UPDATE_AUTHOR': {
            return {
                ...state,
                authors: {...state.authors, [action.value.userId]: action.value}
            };
        }

        case 'UPDATE_IMPORTING_STRUCTURE': {
            return {
                ...state,
                importingStructure: action.value
            };
        }

        case 'UPDATE_IMPORTING_CHECKS': {
            return {
                ...state,
                importingChecks: action.value
            };
        }

        case 'UPDATE_MAP_PARAMS': {
            return {
                ...state,
                map: action.value
            };
        }

        case 'UPDATE_PENDING_DATA': {
            return {
                ...state,
                pendingData: action.value
            };
        }

        case 'UPDATE_CREATORS': {
            return {
                ...state,
                creators: action.value
            };
        }

        case 'UPDATE_GROUP_INVITATIONS': {
            return {
                ...state,
                groupInvitations: action.value
            };
        }

        case 'UPDATE_EXTENDABLE_OBJECTS': {
            return {
                ...state,
                extendableObjects: action.value
            };
        }

        case 'UPDATE_HOME_PATH': {
            if (!Array.isArray(action.value)) {
                return state
            }

            return {
                ...state,
                homePagePath: action.value
            };
        }

        default: {
            return state;
        }

    }


};
