import React from 'react';
import OptionsBar from "../components/OptionsBar";
import {connect} from "react-redux";
import PendingList from "../components/PendingList/PendingList";


class PendingPage extends React.Component {

    render() {
        return (
            <div>
                <OptionsBar>
                    Pending list
                </OptionsBar>

                <PendingList />
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    const {objects} = state;

    return {
        objects
    };
};

export default connect(mapStateToProps)(PendingPage);
