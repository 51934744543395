import * as Cookies from 'browser-cookies';
import ServerManager from "./ServerManager";
import UserData from "../models/UserData.ts";
import storeDispatcher from '../storeDispatcher';
import InfoManager from "./InfoManager";
import md5 from "js-md5";
import StoreManager from "./StoreManager";


export default class Utils {

    static devMode = !!(window.location.toString().indexOf('adminsb') > -1 || window.location.toString().indexOf('localhost') > -1);

    static timestamp = () => {
        let foo = new Date();
        return parseInt(foo.getTime() / 1000);
    };

    static checkSSL () {
        const url = window.location.toString();

        if (url.indexOf('https://') < 0 && url.indexOf('localhost') < 0 ) {
            window.location.href = url.replace('http:', 'https:');
        }
    }

    static get sessionCookie() {
        return Cookies.get(`sessionId${this.devMode ? 'Dev' : 'Prod'}`)
    }

    static set sessionCookie(value) {
        if (value === null) {
            Cookies.erase(`sessionId${this.devMode ? 'Dev' : 'Prod'}`);
            return true;
        }
        return Cookies.set(`sessionId${this.devMode ? 'Dev' : 'Prod'}`, value, {expires: 30});
    }

    static async startAuth(sessionId, email, password) {

        // console.log(sessionId);
        let answer;

        if (sessionId) {
            answer = await ServerManager.instance.webSessionEnter(sessionId);
        }
        else if (email && password) {
            answer = await ServerManager.instance.webLogin(email, password);
        }

        console.log(answer);
        if (!answer || !answer.status) {
            return answer ? InfoManager.showMsg(answer.msg || 'Server error') : null;
        }

        Utils.saveAuthData(answer.user);
        StoreManager.updateGroupInvitationsExisting(answer.invitationCount || 0)

        if (!sessionId) window.location.reload();

    }

    static saveAuthData(user) {
        this.sessionCookie = user.sessionId;

        // add to redux store me and groups
        storeDispatcher.logIn(new UserData(user));
        StoreManager.updatePendingObjectList(false);
    }

    static async signOut() {
        await InfoManager.confirmDialog('Do you really want to logout ?');
        const success = await ServerManager.instance.webLogout();
        if (success) {
            storeDispatcher.logIn(false);
            Utils.sessionCookie = null;
            window.location.reload();
        } else {
            InfoManager.showMsg('Error');
        }
    }

    static checkRegistrationData(email, password, password2, nickname) {
        if (password !== password2) return false;
        if (password.length < 5) return false;
        if (nickname.length < 1) return false;

        return !(email.length < 6 || !email.includes('@') || !email.includes('.'));
    }

    static async signUp(email, password, password2, nickname) {
        if (!Utils.checkRegistrationData(email, password, password2, nickname)) {
            InfoManager.showMsg('Wrong email address or password format, or nickname is empty.');
            return;
        }

        const passwordHash = md5(password);

        const result = await ServerManager.instance.webSignUp(email, passwordHash, nickname);
        if (result.status) {
            InfoManager.showMsg('Check your email please');
        } else {
            InfoManager.showMsg(result.msg);
        }
    }

    static forgotPassword(email) {
        if (email.length < 6 || !email.includes('@') || !email.includes('.')) {
            InfoManager.showMsg('Wrong email address format.');
        } else {
            ServerManager.instance.webPasswordChange(email).then(
                answer => {
                    if(answer.status){
                        InfoManager.showMsg('Check your email.');
                    }else{
                        InfoManager.showMsg(answer.msg);
                    }
                }
            );
        }
    }

    static routerHistory

    static routerPushHistory(url) {
        if (this.routerHistory) {
            this.routerHistory.push(url)
        }
    }

}

export const uniqueID = () => md5((Math.random() * Math.random() + Math.random()).toString());
