import React from 'react';


export default class NotFoundPage extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className='centered'>
                    <i style={{'fontSize': '10em'}} className="far fa-frown"/>
                    <br/>
                    <h2>Page not found</h2>
                </div>
            </React.Fragment>
        )
    }
}
