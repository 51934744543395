import React from 'react';
import OptionsBar from "../components/OptionsBar";
import {connect} from "react-redux";
import Profile from "../components/Profile";
import StoreManager from "../managers/StoreManager";
import SearchBar from "../components/ObjectList/standard-mode/SearchBar";


class ProfilePage extends React.Component {

    render() {
        const {me, users} = this.props;
        const userId = this.props.match.params.userId || me.userId;
        const user = users[userId];

        if (!user) StoreManager.updateUser(userId);

        return (
            <div style={{width: "100%"}}>
                <OptionsBar>
                    <SearchBar />
                </OptionsBar>

                <div className='MainContainer'>
                    <Profile user={user} me={me} itsMe={user && me.userId === user.userId} />
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    const {me, users} = state;
    return {me, users};
};

export default connect(mapStateToProps)(ProfilePage);
