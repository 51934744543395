import React from "react"
import FilterManager, {filterDefaultMap} from "../../../managers/FilterManager"
import {connect} from "react-redux"
import UserData from "../../../models/UserData";
import PopupInputDialog, {InputDataType} from "../../PopupInputDialog/PopupInputDialog";
import {Rights} from "../../../models/TaggableData";
import ProjectData from "../../../models/ProjectData";
import InfoManager from "../../../managers/InfoManager";
import ServerManager from "../../../managers/ServerManager";
import ProjectCreateForm from "../../ProjectList/ProjectCreateForm";


const createObjectInputData: Array<InputDataType> = [
    {
        id: 'language',
        type: 'language',
        allowUndefined: true,
        header: 'Select description language'
    },
    {
        id: 'description',
        type: 'text',
        header: 'Enter description'
    },
    {
        id: 'groupId',
        type: 'select',
        header: 'Select the group',
        options: {

        }
    },
    {
        id: 'ParentObjectId',
        type: 'object',
        header: 'Add inside object',
    }
]


class SearchBar extends React.Component<any, any>
{

    state = {
        isCreateObjectDialogActive: false,
        isCreateMenuActive: false,
        isCreateGroupDialogActive: false
    }

    onChange (e: any) {
        if (e.key === "Enter") {
            FilterManager.set("line", this.searchLineRef.current.value)
        }
    }

    onAddNewObject(data: any) {
        let {description, language, groupId, ParentObjectId} = data

        if (!description) {
            InfoManager.showMsg("You should add description")
            return
        }

        if (!groupId) {
            const me: UserData = this.props.me
            const projects: Array<ProjectData> = this.props.projects

            const group = Object.values(projects)
                .find(group => group.canUserAddObject(me))
            if (group) {
                groupId = group.groupId
            }
        }

        InfoManager.lastAddedObjectGroupId = groupId


        const object = {
            objectId: true,
            tags: {
                [language ? 'Description_' + language : 'Description']: description,
                UserGroupId: groupId,
                ParentObjectId
            }
        }

        ServerManager.instance.addNew(object).then(answer => {
            console.log({answer, object})
        })

    }

    onShowAddObjectDialog(show = true) {
        // this.showCreateDialog(false)
        if (!show) {
            return this.setState({
                isCreateObjectDialogActive: show
            })
        }
        const me: UserData = this.props.me
        const projects: Array<ProjectData> = this.props.projects

        const canAddObject = (me && me.hasRight && me.hasRight(Rights.CREATE))
            || Object.values(projects).find(group => group.canUserAddObject(me))

        if (!canAddObject) {
            return this.setState({
                isCreateObjectDialogActive: false
            })
        }

        const userGroups = Object.values(projects)
            .filter(group => group.canUserAddObject(me))
            .map(group => [group.groupId, group.description])

        const indexGID = createObjectInputData.findIndex(it => it.id === 'groupId')
        const indexPOID = createObjectInputData.findIndex(it => it.id === 'ParentObjectId')

        createObjectInputData[indexGID].options = Object.fromEntries(userGroups)

        // Parent id
        if (FilterManager.filterMap.filterParentId) {
            createObjectInputData[indexPOID].defaultValue = FilterManager.filterMap.filterParentId
            createObjectInputData[indexPOID].useDefaultValueOnSubmit = true
        }
        else {
            delete createObjectInputData[indexPOID].defaultValue
            delete createObjectInputData[indexPOID].useDefaultValueOnSubmit
        }

        // GroupId
        if (FilterManager.filterMap.filterObjectGroup !== filterDefaultMap.filterObjectGroup) {
            createObjectInputData[indexGID].defaultValue = FilterManager.filterMap.filterObjectGroup
            createObjectInputData[indexGID].useDefaultValueOnSubmit = true
        }
        else if (InfoManager.lastAddedObjectGroupId) {
            createObjectInputData[indexGID].defaultValue = InfoManager.lastAddedObjectGroupId
            createObjectInputData[indexGID].useDefaultValueOnSubmit = true
        }
        else {
            delete createObjectInputData[indexGID].defaultValue
            delete createObjectInputData[indexGID].useDefaultValueOnSubmit
        }

        this.setState({
            isCreateObjectDialogActive: show
        })
    }

    searchLineRef: any = React.createRef()

    showCreateDialog(show: any = undefined) {
        this.setState({
            isCreateMenuActive: show === undefined ? !this.state.isCreateMenuActive : show
        })
    }

    showCreateGroupDialog(value = true) {
        this.setState({isCreateGroupDialogActive: value})
    }

    render() {
        const me: UserData = this.props.me
        const projects: Array<ProjectData> = this.props.projects

        const canAddObject = (me && me.hasRight && me.hasRight(Rights.CREATE))
            || Object.values(projects).find(group => group.canUserAddObject(me))


        return (
            <>
                <div className="SearchBarMain">
                    <div className="SearchBarMainItem">
                        <input className="SBInput"
                               type="text"
                               placeholder="Search"
                               ref={this.searchLineRef}
                               onKeyPress={this.onChange.bind(this)}
                        />

                        <i className="far fa-search SBInputIcon"/>
                    </div>

                    <div className="SBButton likeBtn SearchBarMainItem"
                         onClick={() => this.showCreateDialog()}>
                        <i className="far fa-plus"/>
                    </div>

                    <div className="SBButton likeBtn SearchBarMainItem">
                        {
                            me.avatarData
                                ? <img src={me.avatarData} alt=""/>
                                : <i className="far fa-user"/>
                        }

                    </div>

                    {
                        this.state.isCreateMenuActive &&
                        <div className="SBPlusMenuBlock"
                             onClick={() => this.showCreateDialog(false)}
                        >
                            <div className="SBPlusMenuItem likeBtn"
                                 onClick={() => this.onShowAddObjectDialog(true)}
                            >
                                Create new object
                            </div>

                            <div className="SBPlusMenuItem likeBtn" onClick={() => this.showCreateGroupDialog(true)}>
                                Create new group
                            </div>

                            <div className="SBPlusMenuItem likeBtn">
                                Invite user
                            </div>
                        </div>
                    }
                </div>

                <PopupInputDialog
                    header="Add new object"
                    isActive={this.state.isCreateObjectDialogActive}
                    onClose={() => this.onShowAddObjectDialog(false)}
                    onSubmit={(data: any) => this.onAddNewObject(data)}
                    inputDataList={createObjectInputData}/>

                <ProjectCreateForm isActive={this.state.isCreateGroupDialogActive}
                                   onClose={() => this.showCreateGroupDialog(false)}/>

            </>
        )
    }
}


const mapStateToProps = ({me, filterMap, projects, homePagePath}: any) => {
    return {
        me,
        filterMap,
        projects
    }
}
export default connect(mapStateToProps)(SearchBar)
