import React from 'react';
import OptionsBar from "../components/OptionsBar";
import {connect} from "react-redux";
import Project from "../components/Project";


class ProjectPage extends React.Component {

    constructor(props) {
        super(props);
        const projectId = props.match.params.projectId;
        const {projects} = props;
        if (projectId) console.log(projectId);
        if (!projects[projectId]) console.log('Should be loaded');
    }

    render() {
        const projectId = this.props.match.params.projectId;
        const {projects, me} = this.props;

        console.log(projects[projectId]);
        return (
            <div style={{width: "100%"}}>
                <OptionsBar>
                    Project
                </OptionsBar>
                <div className='MainContainer'>
                    <Project project={projects[projectId]} me={me} />
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    objects: state.objects,
    me: state.me,
    projects: state.projects
});

export default connect(mapStateToProps)(ProjectPage);
