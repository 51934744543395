import React from 'react'
import './ProjectList.css'
import placeholder from '../../resources/placeholder.jpg'
import StoreManager from "../../managers/StoreManager";


export default class ProjectListItem extends React.Component {

    openProject = () => {
        const {hierarchicalBehaviour, project, history} = this.props

        if (hierarchicalBehaviour) {
            console.log("Open project clicked")
            StoreManager.changeHomePath({
                id: project.projectId,
                title: project.description
            })
        }
        else {
            console.log(project)
            history.push(`/project/${project.projectId}`)
        }
    }

    render() {
        const {project} = this.props

        return (
            <div className="ObjectItemContainer likeBtn" onClick={this.openProject}>
                <div className="OIAvatarContainer">
                    <img className="OIAvatar" alt="" src={project.avatarData || placeholder} />
                </div>

                <div className="OIDescriptionContainer">
                    {project.description}
                </div>
            </div>
        )

        /*return (
            <div className="ProjectListItem" onClick={this.openProject}>

                <div className="ProjectItemLeft">

                </div>

                <div className="ProjectItemRight">
                    <div className="ProjectItemName">
                        {project.description}
                    </div>

                    <div className="ProjectItemRegistered">
                        {project.isPublic ? 'Public' : 'Private'} project
                        <small> ~ {project.memberCount} <i className="far fa-user" /></small>
                    </div>

                    <div className="ProjectItemLastVisit">
                        {project.comment}
                    </div>
                </div>

                <div className={`likeBtn ProjectItemAlertBlock ${project.requestCount ? '' : 'hided'}`}
                     title="You have new membership requests">
                    {project.requestCount}{' '}
                    <i className="fas fa-bell" />
                </div>

            </div>
        )*/

    }
}
