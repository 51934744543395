import React from "react"
import {connect} from "react-redux"
import StoreManager from "../../managers/StoreManager"
import "./Home.css"

type PropsType = {
    homePagePath: {id: string, title: string}[]
}


class HomePath extends React.Component<PropsType, any> {

    componentDidMount() {
        StoreManager.changeHomePath(undefined)
    }

    render() {
        const {homePagePath} = this.props

        return (
            <div className="">
                <span className="likeBtn HPPathSegment"
                      onClick={() => {
                          StoreManager.changeHomePath(undefined)
                      }}>

                      {` Groups / `}

                </span>

                {
                    homePagePath.map((route, ind, arr) => {
                        const isLastElement = arr.length - 1 === ind

                        return (
                            <span className="likeBtn HPPathSegment"
                                  key={"Route_" + route.id}
                                  onClick={() => {
                                      StoreManager.changeHomePath(route)
                                  }}>

                                    {route.title}

                                {
                                    !isLastElement && " / "
                                }
                            </span>
                        )
                    })
                }
            </div>
        )
    }
}


const mapStateToProps = ({homePagePath}: Record<string, any>) => {
    return {
        homePagePath
    }
}
export default connect(mapStateToProps)(HomePath)
