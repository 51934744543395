import React from "react";
import './LoginPage.css';
import Utils from "../../managers/Utils";
import {connect} from "react-redux";
import mainLogo from "../../resources/mainLogo1.svg"


class LoginPage extends React.Component {

    onLogin() {
        const email = this.SIEmailRef.current.value;
        const password = this.SIPasswordRef.current.value;

        Utils.startAuth(null, email, password).then();
    }

    onForgot() {
        const email = this.SIEmailRef.current.value;
        Utils.forgotPassword(email);
    }

    onSignUp() {
        const email = this.SUEmailRef.current.value;
        const password = this.SUPasswordRef.current.value;
        const password2 = this.SUPassword2Ref.current.value;
        const nickname = this.SUNicknameRef.current.value;

        Utils.signUp(email, password, password2, nickname).then();
    }

    SIEmailRef = React.createRef();
    SIPasswordRef = React.createRef();
    SUEmailRef = React.createRef();
    SUPasswordRef = React.createRef();
    SUPassword2Ref = React.createRef();
    SUNicknameRef = React.createRef();

    render() {
        if (this.props.isLoggedIn || this.props.isLoggedIn === undefined) {
            return null
        }

        return (
            <div className={"LPContainer"}>

                <img src={mainLogo}
                     className="LPLogo"
                     alt=""/>

                <div className='LPSignInBlock'>
                    <div className="LPBlockHeader">
                        Sign in
                    </div>
                    <br/>
                    <form className="LPForm">
                        <div className="LPFormLine">
                            <div className="LPFormHeader">
                                Email
                            </div>
                            <div className="LPFormInput">
                                <input className="LPInput" type="email" autoComplete="email" ref={this.SIEmailRef}/>
                            </div>
                        </div>

                        <div className="LPFormLine">
                            <div className="LPFormHeader">
                                Password
                            </div>
                            <div className="LPFormInput">
                                <input className="LPInput" type="password" autoComplete="current-password"
                                       id="LoginPassword" ref={this.SIPasswordRef}/>
                            </div>
                        </div>
                    </form>

                    <div id="LoginButton" className="likeBtn LPButton" onClick={() => this.onLogin()}>
                        Sign in
                    </div>
                    <div className="likeBtn" id="generatePassword" onClick={() => this.onForgot()}>Forgot password?</div>
                </div>



                <div className='LPSignUpBlock'>
                    <div className="LPBlockHeader">
                        Sign up
                    </div>
                    <br/>
                    <form>
                        <div className="LPFormLine">
                            <div className="LPFormHeader">
                                Email
                            </div>
                            <div className="LPFormInput">
                                <input className="LPInput" type="email" autoComplete="email"
                                       id="SignUpEmail" ref={this.SUEmailRef} />
                            </div>
                        </div>

                        <div className="LPFormLine">
                            <div className="LPFormHeader">
                                Password
                            </div>
                            <div className="LPFormInput">
                                <input className="LPInput" type="password" autoComplete="new-password" id="SignUpPassword"
                                       aria-autocomplete="list" ref={this.SUPasswordRef} />
                            </div>
                        </div>

                        <div className="LPFormLine">
                            <div className="LPFormHeader">
                                Retype password
                            </div>
                            <div className="LPFormInput">
                                <input className="LPInput" type="password" autoComplete="new-password" id="SignUpRetypePassword"
                                       ref={this.SUPassword2Ref} />
                            </div>
                        </div>

                        <div className="LPFormLine">
                            <div className="LPFormHeader">
                                Nickname
                            </div>
                            <div className="LPFormInput">
                                <input className="LPInput" type="text" autoComplete="nickname"
                                       ref={this.SUNicknameRef} />
                            </div>
                        </div>
                    </form>
                    <div className="likeBtn LPButton" onClick={() => this.onSignUp()}>
                        Sign up
                    </div>
                </div>

            </div>
        )
    }
}


const mapStateToProps = state => ({
    isLoggedIn: state.isLoggedIn
});
export default connect(mapStateToProps)(LoginPage);
