import React from 'react';
// import './ObjectList.css';
import './style.sass';
import ObjectListItem from "./standard-mode/ObjectListItem";
import {connect} from "react-redux";
import StoreManager from "../../managers/StoreManager";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import SelectionBar from "./standard-mode/SelectionBar";
import FilterManager from "../../managers/FilterManager";
import ErrorBoundary from "../ErrorBoundary";

export const sortByNumbersFuncFactory = (getValueFunc) => (a, b) => {

    let aVal = getValueFunc(a).replace(/^[^.\d]+/,"");
    let bVal = getValueFunc(b).replace(/^[^.\d]+/,"");

    if ((!aVal || !bVal) || (aVal === bVal)) {
        aVal = getValueFunc(a);
        bVal = getValueFunc(b);
    }

    const aSplit = aVal.split(' ')[0].split('.');
    const bSplit = bVal.split(' ')[0].split('.');

    const maxDepth = aSplit.length > bSplit.length ? aSplit.length : bSplit.length;
    for (let i = 0; i < maxDepth; i++) {
        const a = aSplit[i];
        const b = bSplit[i];

        if (a !== b) {
            if (!a && i > 0) return -1;
            if (!b && i > 0) return 1;

            if (parseInt(a) && parseInt(b) && parseInt(a).toString() === a && parseInt(b).toString() === b) return parseInt(a) - parseInt(b);

            return aVal > bVal ? 1 : (aVal === bVal ? 0 : -1);
        }
    }

    return aVal > bVal ? 1 : (aVal === bVal ? 0 : -1);
};


class ObjectList extends React.Component {

    componentDidMount() {
        // FilterManager.downloadedObjectCount = 0;
        // if (this.props.objectList.length < 12) StoreManager.updateObjectList();
        if (!this.props.hierarchicalBehaviour/* && this.props.objectList.length < 12*/) {
            FilterManager.clean(true)
        }
        if (Object.keys(this.props.projects).length < 1) StoreManager.updateProjectList();
        // window.addEventListener('scroll', (e) => this.scrollHandler(e));
    }

    componentWillUnmount() {
        // window.removeEventListener('scroll', (e) => this.scrollHandler(e));
    }

    /*scrollHandler(e) {
        // console.log(e)

        let st = window.pageYOffset; //$(document).scrollTop();
        let scrollHeight = Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        );

        if(scrollHeight - (st + document.documentElement.clientHeight) < 1000) {
            //download next objects
            const count = this.props.objectList.length;

            if (count - 11 > FilterManager.downloadedObjectCount) {
                FilterManager.downloadedObjectCount = count;
                StoreManager.updateObjectList(count);
            }
        }
    }*/

    downloadMoreObjects() {
        const count = this.props.objectList.length;

        if (count - 14 > FilterManager.downloadedObjectCount) {
            FilterManager.downloadedObjectCount = count;
            StoreManager.updateObjectList(count);
        }
    }

    render() {
        const {objectList, selectedObjectList: selected, historyPush, me,
            objectCount, creators, hierarchicalBehaviour = false, homePagePath} = this.props
        const count = objectList.length

        if (hierarchicalBehaviour && homePagePath && homePagePath.length >= 2) {
            // try to sort objects by comment
            objectList
                .sort(sortByNumbersFuncFactory(it => it.description()))
                .sort((a, b) => {
                if (a.comment > b.comment) {
                    return 1
                }
                if (a.comment < b.comment) {
                    return -1
                }
                return 0
            })
        }

        return (
            <React.Fragment>
                <div className='ObjectListBlock'>
                    {
                        objectList.map( item => {
                            const isSelected = selected.includes(item.objectId);
                            const creator = creators[item.creatorId];
                            if (!creator) StoreManager.updateCreator(item.creatorId);

                            return (
                                <ErrorBoundary className='ObjectListItem'
                                               id={item.objectId}
                                               key={"ObjectItem_" + item.objectId}>
                                    <ObjectListItem object={item}
                                                    historyPush={historyPush}
                                                    hierarchicalBehaviour={hierarchicalBehaviour}
                                                    me={me}
                                                    creator={creator}
                                                    isSelected={isSelected} />
                                </ErrorBoundary>
                            )
                        })
                    }


                </div>

                {
                    count < objectCount &&
                    <button className="OLBDownloadButton"
                            onClick={()=> this.downloadMoreObjects()}>
                        Download more ...
                    </button>
                }

                <AudioPlayer/>
                <SelectionBar selected={selected} />
            </React.Fragment>
        )
    }
}


const mapStateToProps = state => {
    const objectList = Object.values(state.objects)
        .filter(it => StoreManager.orderingList.includes(it.objectId))
        .sort((a, b) => {
            const aIndex = StoreManager.orderingList.indexOf(a.objectId)
            const bIndex = StoreManager.orderingList.indexOf(b.objectId)
            return aIndex > bIndex ? 1 : -1
        })

    return {
        objectList,
        projects: state.projects,
        creators: state.creators,
        me: state.me,
        objectCount: state.objectCount,
        selectedObjectList: state.selectedObjectList,
        homePagePath: state.homePagePath
    }
};

export default connect(mapStateToProps)(ObjectList);
