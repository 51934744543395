import React from "react"
import OptionsBar from "../components/OptionsBar"
import StoreManager from "../managers/StoreManager"
import SearchBar from "../components/ObjectList/standard-mode/SearchBar"
import HomePath from "../components/Home/HomePath"
import Home from "../components/Home/Home"
import Utils from "../managers/Utils";


export default class HomePage extends React.Component {
    constructor(props) {
        super(props)
        if (props.history) Utils.routerHistory = props.history
    }

    render() {
        return (
            <div style={{width: "100%"}}>
                <OptionsBar>
                    <SearchBar />
                    <HomePath />
                </OptionsBar>
                <div className='MainContainer'>
                    <Home history={this.props.history} />
                </div>
            </div>
        )
    }
}
