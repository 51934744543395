import React from 'react';
import Repository from "../components/Repository";


export default class RepositoryPage extends React.Component {

    render() {
        return (
            <div style={{width: "100%"}}>
                <Repository />
            </div>
        )
    }
}
