import React from "react";
import {connect} from "react-redux";
import './ObjectEditor.css';
import storeDispatcher from "../../storeDispatcher";
import ObjectLIInfoBlock from "../ObjectList/standard-mode/ObjectLIInfoBlock";
import DescriptionList from "../ObjectList/standard-mode/DescriptionList";
import Options from "./Options";
import Activator from "./Activator/Activator";
import StoreManager from "../../managers/StoreManager";
import DescriptionMenu from "./DescriptionMenu";
import ServerManager from "../../managers/ServerManager";
import MediaList from "./MediaList";
import PopupMenu from "../PopupMenu";
import {MediaType, mediaTypeName} from "../../models/MediaData";
import AdditionalTagEditor from "../AdditionalTagEditor/AdditionalTagEditor";
import PopupInputDialog from "../PopupInputDialog/PopupInputDialog";
import InfoManager from "../../managers/InfoManager";
import ActivatorEditor from "../ActivatorEditor/ActivatorEditor";
import ActivatorData, {ActivatorType} from "../../models/ActivatorData";
import Utils from "../../managers/Utils";


class ObjectEditor extends React.Component {

    state = {
        isTagsEditorActive: false,
        isDescriptionDialogOpen: false,
        activatorEditor: {
            isActive: false,
            activator: null
        },
        descriptionInputData: [
            {
                id: "language",
                type: "language",
                header: "Language"
            },
            {
                id: "description",
                type: "text",
                header: "Description"
            }
        ]
    };

    onTagsEditorShow(value = true) {
        this.setState({
            isTagsEditorActive: value
        });
    }

    downloadStarted = null;

    historyPush(url) {
        Utils.routerPushHistory(url)
    }

    componentDidUpdate(prevProps, prevState, ss) {
        // download object if not exist
        if (this.props.objectId && !this.props.editingObject && this.downloadStarted !== this.props.objectId) {
            console.log('Should download object data');
            this.downloadStarted = this.props.objectId;
            StoreManager.updateObject(this.props.objectId).then(() => {
                this.downloadStarted = null;
                StoreManager.updateActivatorsData(this.props.objectId).then();
            });
            return;
        }

        if (this.props.objectId && !prevProps.objectId && this.props.editingObject && !this.downloadStarted) {
            StoreManager.updateActivatorsData(this.props.objectId).then();
        }

        // Managing history (url)
        if ((prevProps.editingObject && this.props.editingObject) || (!prevProps.editingObject && !this.props.editingObject)) return;
        console.log('didUpdate checks passed');

        if (this.props.editingObject) {
            // Opening handler
            //this.historyPush(`/objects/${this.props.editingObject.objectId}`);
            if (this.props.projectList.length < 2) StoreManager.updateProjectList();
            console.log(this.props.editingObject);
        } else {
            // Closing handler
            //this.historyPush(`/objects`);
        }
    }

    onClose() {
        storeDispatcher.objectEdit(null);
    }

    onShowDescriptionDialog(show = true) {
        this.setState({
            isDescriptionDialogOpen: show
        })
    }

    onAddDescription({description, language}) {
        const {editingObject} = this.props;

        if (!language) return InfoManager.showMsg("You should select the language.");
        if (!description) return InfoManager.showMsg("You should enter description text.");
        if (editingObject.tags[`Description_${language}`])
            return InfoManager.showMsg("You can't add more then one description with the same language.");

        const tag = [{
            [`Description_${language}`]: description
        }];

        ServerManager.instance.setTags(editingObject.objectId, null, null, null, null, tag).then();

    }

    onCreateSpeek() {
        storeDispatcher.mediaEdit({mediaId: false, mediaType: MediaType.speak});
    }

    onEditActivator(activator) {
        this.setState({
            activatorEditor: {
                isActive: true,
                activator
            }
        })
    }

    onAddActivator(type) {
        const {editingObject: {objectId}} = this.props;
        const activator = new ActivatorData({tags: {ActivatorType: type}, objectId, activatorId: null});

        this.setState({
            activatorEditor: {
                isActive: true,
                activator
            }
        })
    }

    render() {
        const {editingObject: object, projectList, creators, me} = this.props;

        if (!object) return <React.Fragment/>;

        return (
            <div className='ObjectEditorBlock'>
                <div className='HeadBlock'>
                    {object.description()}
                </div>

                <i className='fas fa-times likeBtn CloseButton'
                   onClick={() => {
                       this.onClose()
                   }}/>

                <div className='EditorBody'>
                    <div className='EditorBlock EditorActivators'>
                        Activator list

                        <PopupMenu iconClassName='likeBtn far fa-plus EditorAddButton'
                                   title='Add new activator'>
                            <div className='likeBtn'
                                 onClick={() => this.onAddActivator(ActivatorType.LOCATION)}>
                                <i className='likeBtn fal fa-broadcast-tower'/>
                                Open by iBeacon
                            </div>
                            <div className='likeBtn'
                                 onClick={() => this.onAddActivator(ActivatorType.CODE)}>
                                <i className='likeBtn fad fa-barcode-read'/>
                                Open by QRCode or Barcode
                            </div>
                            <div className='likeBtn'
                                 onClick={() => this.onAddActivator(ActivatorType.LOCATION)}>
                                <i className='likeBtn fad fa-map-marked-alt'/>
                                Open by geolocation
                            </div>
                            <div className='likeBtn'
                                 onClick={() => this.onAddActivator(ActivatorType.DIRECTION)}>
                                <i className='likeBtn fad fa-bullseye-pointer'/>
                                Open by pointing in direction
                            </div>
                            <div className='likeBtn'
                                 onClick={() => this.onAddActivator(ActivatorType.DRIVE_THRU)}>
                                <i className='likeBtn fad fa-car-bus'/>
                                Open when driving though
                            </div>
                        </PopupMenu>

                        <br/>
                        {
                            object.activators.map(activator => {
                                const creator = creators[activator.creatorId];
                                if (!creator) StoreManager.updateCreator(activator.creatorId);

                                return <Activator activator={activator}
                                                  onEdit={() => this.onEditActivator(activator)}
                                                  creator={creator}
                                                  key={activator.activatorId}/>
                            })
                        }
                    </div>

                    <div className='EditorBlock'>
                        Media list
                        <PopupMenu iconClassName='likeBtn far fa-plus EditorAddButton'
                                   title='Add new media'>
                            <div className='likeBtn'
                                 title='Add new soundtrack or text for speech'
                                 onClick={() => this.onCreateSpeek()}>
                                <i className='likeBtn fal fa-headphones-alt'/>
                                {
                                    mediaTypeName.speak
                                }
                            </div>
                            <div className='likeBtn'
                                 title='Add new avatar'
                                 onClick={() => storeDispatcher.mediaEdit({
                                     mediaId: false,
                                     mediaType: MediaType.avatar
                                 })}>
                                <i className='likeBtn fad fa-image'/>
                                {
                                    mediaTypeName.avatar
                                }
                            </div>
                            <div className='likeBtn'
                                 title='Add new link'
                                 onClick={() => storeDispatcher.mediaEdit({
                                     mediaId: false,
                                     mediaType: MediaType.webLink
                                 })}>
                                <i className='likeBtn far fa-link'/>
                                {
                                    mediaTypeName.webLink
                                }
                            </div>
                            <div className='likeBtn'
                                 title='Add new related object'
                                 onClick={() => storeDispatcher.mediaEdit({
                                     mediaId: false,
                                     mediaType: MediaType.relatedObj
                                 })}>
                                <i className='likeBtn fal fa-project-diagram'/>
                                {
                                    mediaTypeName.relatedObj
                                }
                            </div>
                            <div className='likeBtn'
                                 title='Add new icon'
                                 onClick={() => storeDispatcher.mediaEdit({mediaId: false, mediaType: MediaType.icon})}>
                                <i className='likeBtn fad fa-images'/>
                                {
                                    mediaTypeName.icon
                                }
                            </div>
                            <div className='likeBtn'
                                 title='Add new text document'
                                 onClick={() => storeDispatcher.mediaEdit({mediaId: false, mediaType: MediaType.textDoc})}>
                                <i className='likeBtn fal fa-text'/>
                                {
                                    mediaTypeName.textDoc
                                }
                            </div>
                        </PopupMenu>

                        <br/>
                        <MediaList object={object}/>
                    </div>

                    <div className='EditorBlock'>
                        Descriptions
                        <i className='likeBtn far fa-plus EditorAddButton'
                           title='Add new description'
                           onClick={() => this.onShowDescriptionDialog()}/>
                        <br/>

                        <DescriptionList object={object} keySuffix="editor_">
                            <DescriptionMenu/>
                        </DescriptionList>
                    </div>

                    <div className='EditorBlock EditorOptions'>
                        Other options

                        <i className={"MediaTagsEditorIcon likeBtn fal fa-tags" + (me.isSuperAdmin ? '' : ' hided')}
                           onClick={() => this.onTagsEditorShow(true)}
                           title="Tags"/>

                        <Options object={object} projectList={projectList}/>
                    </div>
                </div>

                <div className='EditorFooter'>
                    <ObjectLIInfoBlock object={object}/>
                </div>

                <AdditionalTagEditor objectId={object.objectId}
                                     isActive={this.state.isTagsEditorActive}
                                     onClose={() => this.onTagsEditorShow(false)}/>

                <PopupInputDialog isActive={this.state.isDescriptionDialogOpen}
                                  header={"New description"}
                                  submitText={'Add'}
                                  onClose={() => this.onShowDescriptionDialog(false)}
                                  inputDataList={this.state.descriptionInputData}
                                  onSubmit={data => this.onAddDescription(data)}/>

                <ActivatorEditor
                    isAdmin={me.isSuperAdmin}
                    isActive={this.state.activatorEditor.isActive}
                    onClose={() => this.setState({activatorEditor: {isActive: false}})}
                    activator={this.state.activatorEditor.activator}/>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    editingObject: state.objects[state.editingObjectId],
    objectId: state.editingObjectId,
    creators: state.creators,
    me: state.me,
    projectList: Object.values(state.projects)
});
export default connect(mapStateToProps)(ObjectEditor);
