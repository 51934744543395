export default {
    defaultAction: (value) => {
        return {
            type: 'DEFAULT_ACTION',
            value
        }
    },

    logIn: (value) => {
        return {
            type: 'LOG_IN',
            value
        }
    },

    projectsUpdate: (value) => {
        return {
            type: 'PROJECTS_UPDATE',
            value
        }
    },

    objectsUpdate: (value) => {
        return {
            type: 'OBJECTS_UPDATE',
            value
        }
    },

    objectEdit: (value) => {
        return {
            type: 'OBJECT_EDIT',
            value
        }
    },

    mediaEdit: (value) => {
        const mediaId = value && typeof value === 'object' ? value.mediaId : value;
        const parentId = value && typeof value === 'object' ? value.parentId : null;
        const mediaType = value && typeof value === 'object' ? value.mediaType : null;

        return {
            type: 'SPEEK_EDIT',
            value: {mediaId, parentId, mediaType}
        }
    },

    speekPlay: (value) => {
        return {
            type: 'SPEEK_PLAY',
            value
        }
    },

    sightView: (value) => {
        return {
            type: 'SIGHT_VIEWER',
            value
        }
    },

    userListUpdate: (value) => {
        return {
            type: 'USERS_UPDATE',
            value
        }
    },

    userUpdate: (value) => {
        return {
            type: 'USER_UPDATE',
            value
        }
    },

    setFilter: (value) => {

        return {
            type: 'SET_FILTER',
            value
        }
    },

    cleanFilter: (value) => {
        return {
            type: 'CLEAN_FILTER',
            value
        }
    },

    objectCounter: (value) => {
        return {
            type: 'OBJECT_COUNT',
            value
        }
    },

    selectObject: (value) => {
        return {
            type: 'SELECT_OBJECT',
            value
        }
    },

    setObjectSelection: (value) => {
        return {
            type: 'SET_OBJECT_SELECTION',
            value
        }
    },

    setObjectSelectorMap: (value) => {
        return {
            type: 'SET_OBJECT_SELECTOR_MAP',
            value
        }
    },

    updateMe: (value) => {
        return {
            type: 'UPDATE_ME',
            value
        }
    },

    updateAuthor: (value) => {
        return {
            type: 'UPDATE_AUTHOR',
            value
        }
    },

    updateImportingStructure: (value) => {
        return {
            type: 'UPDATE_IMPORTING_STRUCTURE',
            value
        }
    },

    updateImportingChecks: (value) => {
        return {
            type: 'UPDATE_IMPORTING_CHECKS',
            value
        }
    },

    updateMapParams: (value) => {
        return {
            type: 'UPDATE_MAP_PARAMS',
            value
        }
    },

    updatePendingData: (value) => {
        return {
            type: 'UPDATE_PENDING_DATA',
            value
        }
    },

    updateCreators: (value) => {
        return {
            type: 'UPDATE_CREATORS',
            value
        }
    },

    updateGroupInvitations: (value) => {
        return {
            type: 'UPDATE_GROUP_INVITATIONS',
            value
        }
    },

    updateExtendableObjects: (value) => {
        return {
            type: 'UPDATE_EXTENDABLE_OBJECTS',
            value
        }
    },

    updateHomePath: (value) => {
        return {
            type: "UPDATE_HOME_PATH",
            value
        }
    }


}
