import MediaData from "./MediaData";
import ActivatorData from "./ActivatorData";
import TaggableData from "./TaggableData";


export default class ObjectData extends TaggableData {

    constructor (objectData: any) {
        super(objectData);

        this.mediaEntries = objectData.mediaEntries ? objectData.mediaEntries.map((item: Record<string, any>) => new MediaData(item)) : [];
        delete this._data.mediaEntries;

        // hierarchy
        this.mediaEntries.forEach(media => {
            if (media.isSubTrack) {
                media.parent = this.mediaEntries.reduce((acc: any, cur) => {
                    return media.isChildOf(cur) ? cur : acc;
                }, null);
            } else {
                media.parent = this;
            }
        });

        this.activators = objectData.activators ? objectData.activators.map((item: Record<string, any>) => {
            const activator = new ActivatorData(item);
            activator.parent = this;
            return activator;
        }) : [];

    }

    static get classes() {
        return [
            ['Undefined','null','selected',''],
            ['Museum','museum', ''],
            ['Art gallery', 'artGallery', ''],
            ['Book', 'book', ''],
            ['Exhibition', 'exhibition', ''],
            ['Building', 'building', ''],
            ['Concept', 'concept', ''],
            ['Team', 'team', ''],

            ['Painting','painting','','palette'],
            ['Photography','photo','','camera'],
            ['Illustration','illustration','','image'],
            ['Sculpture','sculpture','','monument'],
            ['Relief','relief','','mountain'],
            ['Architecture','architecture','','mosque'],

            ['Diorama', 'diorama', ''],
            ['Object 3D', 'object3D', ''],

            ['Distant View','distantView','','binoculars'],
            ['Detail','detail','','search'],
            ['Person','person','','user'],
            ['Place','place','','times'],
            ['Exclusive','exclusive','','times'],

            ['Location plan','locationPlan',''],

            ['Logical group','logicalGroup','','times'],
            ['Project','project','','users'],
        ]
    }

    static get activationRoles() {
        return [
            {id: false, name: 'Undefined'},
            {id: "background", name: "Background"},
            {id: "exclusive", name: "Exclusive"},
            {id: "permanent", name: "Permanent"}
        ]

    }

    static findImage(data: Record<string, any>) {
        try {
            const avatarMedia = data.mediaEntries.find((it: any) => it.mediaType === 'avatar');
            if (avatarMedia && avatarMedia.fileData) return 'data:image/png;base64,' + avatarMedia.fileData;

            return data.activators.length > 0 ?
                ('data:image/png;base64,' + data.activators.find((item: any) => !!(item.recogImage)).recogImage)
                : '';
        } catch (e) {
            return '';
        }

    }

    get objectId(): string {return this._data.objectId};

    get publicId(): string | null {return this.tags.PublicId || null};

    get revision(): string {return this._data.revision};

    mediaEntries: Array<MediaData>;

    get orderedSpeekList(): Array<MediaData> {
        return MediaData.sortByPrefPrevTag(this.mediaEntries);
    }

    activators: Array<ActivatorData>;

    get languageDepDescriptionList(): Array<Record<'description' | 'language', string>> {

        return Object.keys(this.tags)
            .filter( key => key.indexOf('Description_') > -1)
            .map( key => ({
                description: this.tags[key],
                language: key.split('_')[1]
            }));
    }

    get mainDescription(): string | null {

        return Object.keys(this.tags).includes("Description")  ? this.tags["Description"] : null
    }

    get getLinks() {    // for Wiki, Youtube and etc ...
        return Object.keys(this.tags).filter((name) => {
            return name.indexOf('Url') > -1;
        }).map((name) => {
            return {
                name,
                value: this.tags[name]
            }
        });
    }

    get anyDescription(): string | null {
        if (this.mainDescription) {
            return this.mainDescription
        }

        const list = this.languageDepDescriptionList;
        if (list.length < 1) return null;

        return this.languageDepDescriptionList[0].description;
    }

    static descriptionFromTags(tags: Record<string, string>): string {

        if (tags.Description_en) return tags.Description_en;
        const entry = Object.entries(tags)
            .find(([key]) => key.includes('Description_'));

        return entry ? entry[1] : 'No description';

    }

    description(selectedLanguage: string = 'en') {
        return this.tags[`Description_${selectedLanguage}`]
            || this.tags[`Description_en`]
            || this.anyDescription
            || "No description";
    }

    get languageList() {

        const descLangList = Object.keys(this.tags).reduce((acc: Array<string>, key: string) => {
            if (key.indexOf('Description_') > -1) {
                const lang: string = key.split('_')[1];
                acc.push(lang);
            }

            return acc;
        }, []);

        const tracksLangList = this.mediaEntries.reduce((acc: Array<string>, track: MediaData) => {

            if (track.tags.Language && track.tags.Language.length === 2) {
                acc.push(track.tags.Language);
            }

            return acc;
        }, []);

        return Array.from(new Set([...descLangList, ...tracksLangList]));
    }

    get avatarImageData(): string | null {
        try {
            const avatarMedia = this.mediaEntries.filter(it => it.isAvatar).find(() => true);
            if (avatarMedia) {
                return avatarMedia.imageData;
            } else {
                return this.imageData || this._data.avatarIamge; // .avatarImage - old format
            }
        } catch (e) {
            console.error(e);
            return null
        }
    }

    get imageData(): string | null {
        try {
            return this._data.activators.length > 0 ?
                ('data:image/png;base64,' + this._data.activators.find((item: any) => !!(item.recogImage)).recogImage)
                : null;
        } catch (e) {
            return null;
        }
    }

    get sightCounter(): number {
        try {
            return this.activators.length || 0;
        } catch (e) {
            return 0;
        }
    }

    get class(): string | null {
        return this.tags.ObjectClass || null;
    }

    get isPerson(): boolean {
        return this.tags.ObjectClass === 'person';
    }

    get classIconName(): string {
        const _class = this.tags.ObjectClass || null;
        const classInfo = ObjectData.classes.find(item => item[1] === _class);

        return classInfo ? 'fas fa-' + classInfo[3] : '';
    }

    get classDescription(): string | null {
        const _class = this.tags.ObjectClass || null;
        const classInfo = ObjectData.classes.find(item => item[1] === _class);

        return classInfo ? classInfo[0] : this.tags.ObjectClass || 'Unclassified';
    }

    get projectId(): string {
        return this.tags.UserGroupId || 'unassigned';
    }

    getActivator(activatorId: string): ActivatorData | null {
        return this.activators.find(item => item.activatorId === activatorId) || null;
    }

    getSpeek(mediaId: string): MediaData | null {
        return this.mediaEntries.find(item => item.mediaId === mediaId) || null;
    }

    get AuthorObjectId(): string | null {
        return this.tags.AuthorObjectId || null;
    }

    get locationForMap() {
        if (this.tags.ObjectCoords) {
            const coords = '{' + this.tags.ObjectCoords.replace('lat', '"lat"').replace('lon', '"lon"').replace('rad', '"rad"') + '}';
            const parsed = JSON.parse(coords);

            return [parsed.lat, parsed.lon, parsed.rad, false];
        } else if (this.activators.length > 0) {
            for (let activator of this.activators) {
                if (activator.location) return [...activator.location, 1, true];
            }
        }

        return null;
    }

    get activationRole(): string | null {
        return this.tags.ActivationRole || null
    }

    get canBeParent(): boolean {
        if (this.class && ["place", "logicalGroup"].includes(this.class)) {
            return true
        }

        if (this.activationRole) {
            return true
        }

        return false
    }

}
