import React from "react"
import {connect} from "react-redux"
import "./Home.css"
import ProjectList from "../ProjectList/ProjectList";
import ObjectList from "../ObjectList/ObjectList";

type PropsType = {
    homePagePath: {id: string, title: string}[],
    history: any
}


class Home extends React.Component<PropsType, any> {

    render() {
        const {homePagePath = [], history} = this.props

        if (homePagePath.length < 1) {
            return (
                <>
                    <ProjectList history={history}
                                 hierarchicalBehaviour={true}/>
                </>
            )
        }

        return (
            <>
                <ObjectList hierarchicalBehaviour={true} />
            </>
        )
    }
}


const mapStateToProps = ({homePagePath}: Record<string, any>) => {
    return {
        homePagePath
    }
}
export default connect(mapStateToProps)(Home)
