import React from 'react'
import OptionsBar from '../components/OptionsBar'
import {connect} from 'react-redux'
import ProjectList from '../components/ProjectList/ProjectList'
import StoreManager from '../managers/StoreManager'
import GroupInvitationList from '../components/ProjectList/GroupInvitationList.tsx'
import SearchBar from "../components/ObjectList/standard-mode/SearchBar";


class ProjectListPage extends React.Component {

    componentDidMount() {
        // StoreManager.updateProjectList();
        if (this.props.count < 1) StoreManager.updateProjectList();
        StoreManager.updateGroupInvitations()
    }

    render() {
        const {groupInvitations, userId} = this.props;

        return (
            <div style={{width: "100%"}}>
                <OptionsBar>
                    <SearchBar />
                </OptionsBar>
                <div className='MainContainer'>
                    {
                        userId &&
                            <GroupInvitationList
                                invitations={groupInvitations}
                                userId={userId}
                            />
                    }
                    <ProjectList history={this.props.history} />
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    const {projects, groupInvitations, me} = state;

    return {
        count: Object.keys(projects).length,
        groupInvitations,
        projects,
        userId: me && me.userId ? me.userId : null
    };
};

export default connect(mapStateToProps)(ProjectListPage);
